<template>
  <div class="step-form-style-desc">
    <a-form layout="inline" style="max-width: 550px; margin: 20px auto">
      <a-row style="width:100%;">
        <a-col :span="16">
          <a-row style="margin-bottom: 5px;">
            <a-col :span="11">
              <a-input :placeholder="$t('route.FirstName')" v-model:value="firstnameInput" @pressEnter="onClickSearchByCustomer" allow-clear/>
            </a-col>
            <a-col :span="11" :offset="1">
              <a-input :placeholder="$t('route.LastName')" v-model:value="lastnameInput" @pressEnter="onClickSearchByCustomer" allow-clear/>
            </a-col>
          </a-row>
          <a-row :wrapperCol="{ span: 16 }">
            <a-col :span="11">
              <a-input :placeholder="$t('route.Email')" v-model:value="emailInput" @pressEnter="onClickSearchByCustomer" allow-clear/>
            </a-col>
            <a-col :span="11" :offset="1">
              <a-input :placeholder="$t('route.Phone')" v-model:value="phoneInput" @pressEnter="onClickSearchByCustomer" allow-clear/>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="7" :offset="1">
          <a-input-group compact>
            <a-input
              :placeholder="$t('route.InvoiceNo')"
              v-model:value="orderNumberInput"
              style="margin-bottom: 5px"
              allow-clear
              @pressEnter="onClickSearchByCustomer"
            />
            <a-button
              type="primary"
              @click="onClickSearchByCustomer"
              style="margin-bottom: 5px"
              :loading="loadingSearchCustomer"
            >{{ $t("route.Search") }}</a-button>
          </a-input-group>
        </a-col>
      </a-row>
    </a-form>

    <ListRmaTable v-if="listRmaData.data && listRmaData.data.length > 0" :columns="searchCustomerRecordColumns" privateData :privateApi="getData" />

  </div>
</template>

<script>
  import { onMounted, computed, reactive, ref, toRefs } from "vue";
  import { notification } from "ant-design-vue";
  import useClientRepositories from "@/composables/useClientRepositories";
  import ListRmaTable from '@/components/listRma'
  import ListRmaState from '@/state/ListRma'
  import { PlusOutlined, SearchOutlined } from "@ant-design/icons-vue";
  import { useI18n } from "vue-i18n";

  export default {
    data() {
      return {};
    },
    components: {
      PlusOutlined,
      SearchOutlined,
      ListRmaTable
    },
    setup() {
      const { getClientRmaData } = useClientRepositories();
      const customers = reactive({ array: [] });
      const firstnameInput = ref("");
      const lastnameInput = ref("");
      const phoneInput = ref("");
      const emailInput = ref("");
      const orderNumberInput = ref("");
      const loadingSearchCustomer = ref(false);
      const { t, locale } = useI18n({ useScope: "global" });

      const { state } = ListRmaState()

      onMounted(() => {
        state.searchData = {}
        state.listRmaData = []
      })

      const searchCustomerRecordColumns = computed(() => {
        const filtered = state.filteredInfo || {};
        const sorted = state.sortedInfo || {};
        const filters = state.statusList || [];
        return[
          {
            title: t('listRMA.sku'),
            key: "img",
            slots: {
              customRender: "img",
              filterDropdown: 'filterDropdown',
              filterIcon: 'filterIcon',
            },
            onFilter: (value, record) => {
              return record.product_sku.toString().toLowerCase().includes(value.toLowerCase())
            },
          },
          {
            title: t('route.RMA'),
            key: "rma_id",
            dataIndex: "RmaID",
            slots: {
              filterDropdown: "filterDropdown",
              filterIcon: "filterIcon",
              customRender: "rmaNumber",
            },
            onFilter: (value, record) => {
              return record.RmaID.toString().toLowerCase().includes(value.toLowerCase())
            },
            sorter: (a, b) => {
              // return a.RmaID.localeCompare(b.RmaID)
            },
            sortOrder: sorted.columnKey === 'rma_id' && sorted.order,
          },
          {
            title: t('user.name'),
            dataIndex: "fullname",
            key: "name"
          },
          {
            title: t('user.email'),
            dataIndex: "email",
            key: "email"
          },
          {
            title: t('route.Phone'),
            dataIndex: "fullphone",
            key: "phone"
          },
          {
            title: t('route.PurchaseDate'),
            dataIndex: "date_of_purchase",
            key: "date_of_purchase",
            slots: {
              filterDropdown: "filterDropdownDate",
              filterIcon: "filterIcon",
            },
            onFilter: (value, record) => {
              return record
            },
            sorter: (a, b) => {
              // return a.date_of_purchase.localeCompare(b.date_of_purchase)
            },
            sortOrder: sorted.columnKey === 'date_of_purchase' && sorted.order,
          },
          {
            title: t('route.WarrantyType'),
            dataIndex: "is_under_warranty",
            key: "is_under_warranty",
            slots: {
              customRender: "isUnderWarranty",
            },
            filters: [
              {
                text: 'YES',
                value: 1
              },
              {
                text: 'NO',
                value: 0
              }
            ],
            filteredValue: filtered.is_under_warranty || null,
            onFilter: (value, record) => {
              return record
            }
          },
          {
            title: t('route.CreateDate'),
            dataIndex: "received_date",
            key: "receive_item_date",
            slots: {
              filterDropdown: "filterDropdownDate",
              filterIcon: "filterIcon",
            },
            onFilter: (value, record) => {
              return record
            }
          },
          {
            title: t('route.Status'),
            key: "RmaStatus",
            // dataIndex: "RmaStatus.Name",
            dataIndex: "RmaStatus",
            slots: {
              customRender: "status",
            },
            filters,
            filteredValue: filtered.RmaStatus || null,
            onFilter: (value, record) => {
              return record.RmaStatus.Name.toString().toLowerCase().includes(JSON.parse(value).label.toLowerCase())
            }
          },
          {
            title: t("route.WaivedFee"),
            key: "waived_fee_status",
            dataIndex: "waived_fee_status",
            slots: {
              customRender: "WaivedFeeStatus",
            }
          }
        ];
      });

      const onClickSearchByCustomer = () => {
        getData()
      };

      const getData = async (tableFilter) => {
        let params = `first_name=${firstnameInput.value}&last_name=${lastnameInput.value}&phone=${phoneInput.value}&email=${emailInput.value}&order_number=${orderNumberInput.value}`
        if (tableFilter) {
          const reg = /\&name\=|\&email\=|\&phone\=/g
          tableFilter = tableFilter.replace(reg, '')
          params += `&${tableFilter}`
        }
        loadingSearchCustomer.value = true
        await getClientRmaData(params)
        if (!state.listRmaData.data || state.listRmaData.data.length == 0) {
          notification.error({
            message: 'Error',
            description: "No matches found"
          })
        }
        loadingSearchCustomer.value = false
      };

      return {
        customers,
        firstnameInput,
        lastnameInput,
        phoneInput,
        emailInput,
        orderNumberInput,
        loadingSearchCustomer,
        onClickSearchByCustomer,
        getData,
        searchCustomerRecordColumns,
        ...toRefs(state),
        t,
        locale,
        getClientRmaData
      };
    },
    methods: {},
  };
</script>
<style lang="scss">
.home {
  padding: 10px;
}
.ant-table-pagination.ant-pagination {
  float: right;
  margin: 8px 0;
}
.ant-table {
  padding-bottom: 25px;
}
.customerlist thead th {
  position: sticky;
  top: 0;
  z-index: 99;
}
</style>