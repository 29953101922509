<template>
  <div class="home">
    <a-button danger class="reset_all_btn" @click="resetAll()" v-if="showResetAll">{{ $t('listRMA.resetAll') }}</a-button>
    <a-table
      :columns="columns"
      :data-source="listRmaData.data"
      @change="handleChange"
      :pagination="pagination"
      :scroll="{ x: 'max-content' }"
      :loading="loading"
    >
      <template #img="{ record }">
        <div>
          {{ record.product_sku }}
          <br>
          <a-image
            width="50px"
            :src="record.img"
            :alt="record.sav"
            fallback="https://www.apm.mc/media/catalog/product/cache/74c1057f7991b4edb2bc7bdaa94de933/a/c/ac3350ox.jpg"
          />
        </div>
      </template>
      <template
        #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <a-input
            ref="searchInput"
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex, clearFilters)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="handleSearch(selectedKeys, confirm, column.dataIndex, clearFilters)"
          >
            <template #icon><SearchOutlined /></template>
            {{ $t('route.Search') }}
          </a-button>
          <a-button
            size="small"
            style="width: 90px"
            @click="handleReset(clearFilters, column.dataIndex)"
          >
            {{ $t('listRMA.reset') }}
          </a-button>
        </div>
      </template>
      <template
        #filterDropdownDate="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div style="padding: 8px">
          <div>
            <a-input
              ref="searchInput"
              placeholder="Start Time"
              :value="selectedKeys[0]"
              style="width: 120px; margin-bottom: 8px;"
              @change="(e) => setSelectedKeys(e.target.value ? [e.target.value, selectedKeys[1] || ''] : ['', selectedKeys[1] || ''])"
              @pressEnter="handleSearchDate(selectedKeys, confirm, column.dataIndex, clearFilters)"
            />
            <span style="width:20px;text-align:center;display:inline-block;">-</span>
            <a-input
              ref="searchInput"
              placeholder="End Time"
              :value="selectedKeys[1]"
              style="width: 120px; margin-bottom: 8px;"
              @change="(e) => setSelectedKeys(e.target.value ? [selectedKeys[0] || '', e.target.value] : [selectedKeys[0] || '', ''])"
              @pressEnter="handleSearchDate(selectedKeys, confirm, column.dataIndex, clearFilters)"
            />
          </div>
          <div style="text-align:center;">
            <a-button
              type="primary"
              size="small"
              style="width: 90px; margin-right: 8px"
              @click="handleSearchDate(selectedKeys, confirm, column.dataIndex, clearFilters)"
            >
              <template #icon><SearchOutlined /></template>
              {{ $t('route.Search') }}
            </a-button>
            <a-button
              size="small"
              style="width: 90px"
              @click="handleReset(clearFilters, column.dataIndex)"
            >
              {{ $t('listRMA.reset') }}
            </a-button>
          </div>
        </div>
      </template>
      <template #filterIcon="{ filtered }">
        <search-outlined :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>
      <template #customRender="{ text, column }">
        <span v-if="searchText && searchedColumn === column.dataIndex">
          <template v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))">
            <mark
              v-if="fragment.toLowerCase() === searchText.toLowerCase()"
              class="highlight"
              :key="i"
            >
              {{ fragment }}
            </mark>
            <template v-else>{{ fragment }}</template>
          </template>
        </span>
        <template v-else>
          {{ text }}
        </template>
      </template>

      <template #rmaNumber="{record}">
        <a-badge :count="record.diff_reason_count" >
          <a @click="ShowRMADetail(record)" style="padding-right: 10px;">{{ record.RmaID }}</a>
        </a-badge>
      </template>
      <template #phone="{record}">
        <div style="width: 150px;">({{ record.phone_code }}) {{ record.phone }}</div>
      </template>
      <template #worker="{record}">
        ({{ record.RmaStatusHistory && record.RmaStatusHistory.length > 0 ? record.RmaStatusHistory[0].CreatedBy : '' }})
      </template>
      <template #status="{record}">
        <span>
          <a-tag :color="record.RmaStatus.ID === 17 ? 'volcano' : record.RmaStatus.ID === 16 ? 'green' : record.RmaStatus.ID > 1 && record.RmaStatus.ID < 14 ? 'geekblue' : 'warning'">
            {{ record.RmaStatus.Name }}
          </a-tag>
        </span>
      </template>
      <template #isUnderWarranty="{record}">
        <div style="text-align: center;">{{ record.is_under_warranty ? 'YES' : 'NO' }}</div>
      </template>
      <template #WaivedFeeStatus="{record}">
        <div style="text-align: center;">{{ $t(`listRMA.waivedFeeStatus_${record.waived_fee_status}`) }}</div>
      </template>
      <template #repairTimes="{ record }">
        <div class="repair_times_box">
          <div>{{ record.repair_history.total_180 }}</div>
          <a-button type="link" :disabled="record.repair_history.total_180 <= 0" @click="viewRepairHistory(record.repair_history.id)">{{ $t('route.viewBtn') }}</a-button>
        </div>
      </template>
    </a-table>

    <RepairTimes ref='repair_times'></RepairTimes>

    <ProductDetail :stateData="ListRmaState" @updateData="updateData"></ProductDetail>
  </div>
</template>
<script>
  import {
    defineComponent,
    ref,
    toRefs,
    computed,
    onMounted
  } from "vue";
  import { SearchOutlined } from "@ant-design/icons-vue";
  import { formatDateWithTime, formatDate } from '@/utils/utils';
  import { useI18n } from 'vue-i18n'
  import { useRoute } from 'vue-router'
  import useListRmaRepositories from "@/composables/useListRmaRepositories"
  import useRmaRepositories from "@/composables/useRmaRepositories"
  import ProductDetail from '@/components/rma/ProductDetail'
  import RepairTimes from '@/components/repairTimes'
  import { useStore } from 'vuex'
  import ListRmaState from '@/state/ListRma'
  import RepairTimesState from '@/state/RepairTimes'

  export default defineComponent({
    props: {
      columns: {
        type: Object,
        default: null
      },
      privateData: {
        type: Boolean,
        default: false
      },
      privateApi: {
        type: Function,
        default: null
      }
    },
    components: {
      SearchOutlined,
      ProductDetail,
      RepairTimes
    },
    setup(props) {
      const store = useStore()
      const { t ,locale } = useI18n({ useScope: 'global' })
      const shopName = store.getters.name
      const trackingCode = ref("");
      const current = ref(1);
      const pageSize = ref(10);
      const isResetAll = ref(false);
      const { state, setSelectedItem } = ListRmaState();
      const { viewRepairHistory } = RepairTimesState();

      const repair_times = ref();

      const pagination = computed(() => {
        return ({
          total: state.tableTotal,
          current: current.value,
          pageSize: pageSize.value,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '20', '50', '100']
        })
      })

      const showResetAll = computed(() => {
        let num = 0
        for (const i in state.filteredInfo) {
          if (state.filteredInfo[i] != null && state.filteredInfo[i] != '') num++
        }
        if (state.sortedInfo.order) num++
        return num > 1
      })

      const { getRmaListData, getRmaStatus } = useListRmaRepositories()
      const { fetchGetSignatures, getCarrierList } = useRmaRepositories()
      const route = useRoute()
      
      onMounted(() => {
        if (route.query?.rma_item_id) {
          state.searchData.RmaID = route.query.rma_item_id
          getRmaList(Number(route.query.rma_item_id))
          window.history.replaceState(null, null, window.location.origin + window.location.pathname);
        } else if (!props.privateData) getRmaList()
        getRmaStatus()
        getCarrierList()
      })

      const handleParams = () => {
        const rma_status_list = []
        if (state.filteredInfo && state.filteredInfo.RmaStatus && state.filteredInfo.RmaStatus.length > 0) {
          state.filteredInfo.RmaStatus.forEach(v => {
            rma_status_list.push(JSON.parse(v).rma_status_id)
          });
        }
        let is_under_warranty = ''
        if (state.filteredInfo && state.filteredInfo.is_under_warranty && state.filteredInfo.is_under_warranty.length > 0) {
          if (state.filteredInfo.is_under_warranty.indexOf(1) > -1 && state.filteredInfo.is_under_warranty.indexOf(0) > -1) {
            is_under_warranty = ''
          } else if (state.filteredInfo.is_under_warranty.indexOf(1) == -1 && state.filteredInfo.is_under_warranty.indexOf(0) > -1) {
            is_under_warranty = '0'
          } else if (state.filteredInfo.is_under_warranty.indexOf(1) > -1 && state.filteredInfo.is_under_warranty.indexOf(0) == -1) {
            is_under_warranty = '1'
          }
        }
        // &receive_item_date=${state.received_date || ''}
        let params = `page=${current.value}&page_size=${pageSize.value}&sku=${state.searchData.SKU || ''}&rma_id=${state.searchData.RmaID || ''}&name=${state.searchData.fullname || ''}&store_id=${state.searchData.store_id || ''}&store_name=${state.searchData.created_by || ''}&email=${state.searchData.email || ''}&phone=${state.searchData.fullphone || ''}&start_date=${state.searchData.received_date &&　state.searchData.received_date.length > 0 ? state.searchData.received_date[0] : ''}&end_date=${state.searchData.received_date &&　state.searchData.received_date.length > 0 ? state.searchData.received_date[1] : ''}&rma_status_id=${rma_status_list.join(',') || ''}&worker=${state.searchData.Worker || ''}&purchase_start_date=${state.searchData.date_of_purchase &&　state.searchData.date_of_purchase.length > 0 ? state.searchData.date_of_purchase[0] : ''}&purchase_end_date=${state.searchData.date_of_purchase &&　state.searchData.date_of_purchase.length > 0 ? state.searchData.date_of_purchase[1] : ''}&is_under_warranty=${is_under_warranty || ''}`
        if (state.sortedInfo && state.sortedInfo.order) {
          params += `&sort_by=${state.sortedInfo.column.key}&desc=${state.sortedInfo.order === 'descend' ? 1 : 0}`
        }
        return params
      }

      const getRmaList = async (id = '') => {
        const params = handleParams()
        await getRmaListData(params)
        if (id != '') {
          const record = state.listRmaData.data.filter(item => item.id === id)
          ShowRMADetail(record[0])
        }
      }

      const updateData = (id) => {
        getRmaList(id)
      }

      const handleChange = (pagination, filters, sorter) => {
        if (isResetAll.value) return
        state.filteredInfo = filters;
        state.sortedInfo = sorter;
        current.value = pagination.current;
        pageSize.value = pagination.pageSize;
        if (!props.privateData) {
          getRmaList()
        } else {
          const params = handleParams()
          props.privateApi(params)
        }
      };

      const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
        confirm();
        state.searchText = selectedKeys[0];
        state.searchedColumn = dataIndex;
        state.searchData[dataIndex] = selectedKeys[0];
        state.clearFiltersFunction[dataIndex] = clearFilters
      };

      const handleSearchDate = (selectedKeys, confirm, dataIndex, clearFilters) => {
        confirm();
        state.searchText = selectedKeys[0];
        state.searchedColumn = dataIndex;
        state.searchData[dataIndex] = selectedKeys;
        state.clearFiltersFunction[dataIndex] = clearFilters
      };

      const handleReset = (clearFilters, dataIndex) => {
        clearFilters();
        state.searchText = "";
        state.searchData[dataIndex] = "";
        delete state.clearFiltersFunction[dataIndex]
      };

      const resetAll = () => {
        state.filteredInfo = {};
        state.sortedInfo = {};
        current.value = 1;
        pageSize.value = 10;
        state.searchText = '';
        state.searchedColumn = '';
        state.searchData = {};
        for(const i in state.clearFiltersFunction) {
          state.clearFiltersFunction[i]()
        }
        state.clearFiltersFunction = {}
        isResetAll.value = true
        setTimeout(() => {
          isResetAll.value = false
        }, 500)
        if (!props.privateData) {
          getRmaList()
        } else {
          const params = handleParams()
          props.privateApi(params)
        }
      };

      const ShowRMADetail = async (record) => {
        const data = `rma_id=${record.RmaID}`
        const res = await fetchGetSignatures(data);
        if(res.success && res.data.ImageUrl != ""){
          record.signUrl = res.data.ImageUrl+'?v='+new Date().getTime();
        }else{
          record.signUrl = "";
        }
        setSelectedItem(record)
      }

      return {
        ...toRefs(state),
        getRmaList,
        pagination,
        current,
        pageSize,
        trackingCode,
        handleParams,
        handleSearch,
        handleSearchDate,
        handleReset,
        ShowRMADetail,
        formatDateWithTime,
        shopName,
        handleChange,
        ListRmaState,
        resetAll,
        showResetAll,
        isResetAll,
        viewRepairHistory,
        repair_times,
        updateData
      };
    },
  });
</script>

<style scoped lang="scss">
.table-action ul{
  display:contents;
}
.ant-table-pagination.ant-pagination {
  float: right;
  margin: 8px 0;
}
.ant-table {
  padding-bottom: 55px;
}
thead th {
  // position: sticky;
  // top: 0;
  // z-index:999;
}

.vl {
  border-left: 1px solid #efefef;
  min-height: 50px;
}
.link-button {
  color: #fff;
  background-color: #001628 !important;
  border-color: #001628 !important;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  padding: 10px;
}

.canc {
  background-color: #FF4D4E;
  color: white;
  padding: 5px;
  border-radius: 15px;
}

.compltd {
  background-color: #86D067;
  color: white;
  padding: 5px;
  border-radius: 15px;
}

.ongoing {
  background-color: #001528;
  color: white;
  padding: 5px;
  border-radius: 15px;
}

.reset_all_btn {
  position: absolute;
  top: 16px;
  left: 350px;
}

.repair_times_box {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .RepairHistory_table {
//   padding-top: 32px;

//   .reason_box {
//     display: flex;
//     flex-wrap: wrap;

//     .reason {
//       margin-right: 10px;
//     }
//   }
// }

</style>